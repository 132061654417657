import React from "react"
import Layout from "../components/Layout/Layout"

const Error = () => {
  return (
    <Layout>
      <div className="container">
        <h1 className='text-center mt-5'>Ooops... Nothing to see here!</h1>
        <div className="container">
          <a href="/" className='btn btn-outline-dark btn-block'>Take Me Back</a>
        </div>

      </div>
    </Layout>
  )
}

export default Error
